// import React from 'react';
import React, { useContext } from 'react';
import Switch from 'react-switch';
import { FiMoon, FiSun } from 'react-icons/fi'
import {
  DefaultTheme
  , ThemeContext
} from 'styled-components';
import Translation from '../../components/Translation';

// import { Link } from 'react-router-dom';

import {
  Container
  , IconContainer
  // , Content
} from './styles';

import LogoColor from '../../assets/SE_Logo_Color_RGB.svg';
import LogoWhite from '../../assets/SE_Logo_White_RGB.svg';

interface HeaderProps {
  size?: 'small' | 'large';
  toggleTheme(): void;
  theme: DefaultTheme;
}

const Header: React.FC<HeaderProps> = ({ size = 'large', toggleTheme, theme }: HeaderProps) => {
  const { title } = useContext(ThemeContext)

  return (
    <Container size={size}>
      <header>
        {theme.title === 'light' ?
          (<img className="logo" src={LogoColor} alt="SiemensEnergyLogo" height={40} />) :
          (<img className="logo" src={LogoWhite} alt="SiemensEnergyLogo" height={40} />)
        }
        {/* <nav>
          <Link to="/rnc">Preencher RNC</Link>

        </nav> */}
        <Switch
          onChange={toggleTheme}
          onColor={theme.colors.secondary}
          checked={title === 'light'}
          uncheckedIcon={
            <IconContainer>
              <FiMoon />
            </IconContainer>
          }
          checkedIcon={
            <IconContainer>
              <FiSun />
            </IconContainer>
          }
          height={20}
        />
        <Translation />
      </header>
    </Container>
  )
};

export default Header;
