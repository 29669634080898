import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isFillable: boolean;
  error: boolean;
}
interface LabelProps {
  isHalf: boolean;
}
interface Pprops {
  colorOptionalDefaul?: 'optional' | 'api';
}

export const Label = styled.label<LabelProps>`
  padding-right: 50px;
  width: ${props => props.isHalf ? css`
    26%
  ` : css` 
    11%
  `};
	display: flex;
	align-items: center;
	justify-content: start;
`;

export const P = styled.p<Pprops>`
  color: transparent;
  ${props =>
    props.colorOptionalDefaul === 'optional' &&
    css`
    color: ${props => props.theme.colors.borderError};
  `}

  ${props =>
    props.colorOptionalDefaul === 'api' &&
    css`
    color: ${props => props.theme.colors.borderActive};
  `}
`

export const ContainerField = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
`;

export const Container = styled.div<ContainerProps>`
  background: ${props => props.theme.colors.inputBackgroundFillable};
  border-radius: 10px;
  padding: 8px;
  width: 100%;
  height: 10px;

  border: 2px solid ${props => props.theme.colors.borderInactive};
  color: ${props => props.theme.colors.text};

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.error &&
    css`
      border: 2px solid ${props => props.theme.colors.borderError};
    `}

  ${props =>
    props.isFocused &&
    css`
      border: 2px solid ${props => props.theme.colors.borderActive};
      color: ${props => props.theme.colors.borderActive};
    `}

  ${props =>
    props.isFilled &&
    css`
      color: ${props => props.theme.colors.borderActive};
    `}


  ${props =>
    !props.isFillable &&
    css`
      background: ${props => props.theme.colors.inputBackground};
      border: 0px;
    `}


  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: ${props => props.theme.colors.textInsideInput};

    ${props =>
    !props.isFillable &&
    css`
        color: ${props => props.theme.colors.text};
      `}

    &::placeholder {
      color: ${props => props.theme.colors.textInsideInput};

      ${props =>
    !props.isFillable &&
    css`
          color: ${props => props.theme.colors.text};
        `}
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: ${props => props.theme.colors.borderError};
    color: #fff;

    &:before {
      border-color: ${props => props.theme.colors.borderError} transparent;
    }
  }
`;
