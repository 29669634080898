import YouTube from 'react-youtube';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
`;

export const Content = styled.div`
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100 %;
  padding: 20px;
`;

export const YoutubeContent = styled(YouTube)`
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;

  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100 %;
  min-height: 100 %;
`