/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
// import { DefaultTheme, ThemeProvider } from 'styled-components';
// import { Options } from 'react-youtube';
import { useTranslation } from "react-i18next";

import flagUS from "../../assets/flags/us.png"
import flagBR from "../../assets/flags/br.png"
import flagES from "../../assets/flags/es.png"

import {
  Content
  // Video
} from './styles';
import GlobalStyle from '../../styles/global';
// import light from '../../styles/themes/light';


const Translation: React.FC = () => {
  const { i18n, ready } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    document.title = "Evoluenergy"
  }, []);

  return (
    <>
      <GlobalStyle />
      <Content>
        {ready && (
          <>
            <button className="pt" onClick={() => changeLanguage("pt")}>
              <img src={flagBR} alt="submit" style={{ height: "15px", width: "25px" }} />
            </button>
            <button className="en" onClick={() => changeLanguage("en")}>
              <img src={flagUS} alt="submit" style={{ height: "15px", width: "25px" }} />
            </button>
            <button className="es" onClick={() => changeLanguage("es")}>
              <img src={flagES} alt="submit" style={{ height: "15px", width: "25px" }} />
            </button>
          </>
        )}
      </Content>
    </>
  );
};

export default Translation;
