import React, { useRef, useState, useEffect, useCallback } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useField } from '@unform/core';
import 'react-datepicker/dist/react-datepicker.css';
import { IconBaseProps } from 'react-icons';
import { Container, ContainerField, Label, Error } from './styles';
import { FiAlertCircle } from 'react-icons/fi';


interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  LabelName: string;
  InitDate: Date | null;
  isFillable: boolean;
  isHalf: boolean;
  icon: React.ComponentType<IconBaseProps> | null;
  colorOptionalDefaul?: 'optional' | 'api';
}

export default function DatePicker({
  name,
  LabelName,
  InitDate,
  isFillable,
  isHalf,
  icon: Icon,
  colorOptionalDefaul,
  ...rest }: Props) {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const datepickerRef = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [date, setDate] = useState(defaultValue || InitDate);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!datepickerRef.current);
  }, []);

  useEffect(() => {

    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <ContainerField>
        <Label isHalf={isHalf}>{LabelName}:</Label>
        <Container error={!!error} isFilled={isFilled} isFocused={isFocused} isFillable={isFillable}>
          {Icon && <Icon size={20} />}

          <ReactDatePicker
            ref={datepickerRef}
            selected={date === null ? null : date}
            onChange={setDate}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            dateFormat="dd/MM/yyyy"
            {...rest}
          />

          {error && (
            <Error title={error}>
              <FiAlertCircle size={20} color="#c53030" />
            </Error>
          )}
        </Container>
      </ContainerField>
      <br />
    </>
  );
};