import React from 'react';
import { DefaultTheme } from 'styled-components';
import { Container } from './styles';

interface AnimatedIconProps {
  color: 'green' | 'red';
  theme: DefaultTheme;
}

const AnimatedIcon: React.FC<AnimatedIconProps> = ({ color, theme }: AnimatedIconProps) => {
  return (  
    <Container>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" id="check-icon" className="animate__flash animate">
        {color === "green" ? (
          <>
            <circle className="path circle" fill="none" stroke={theme.colors.checkSuccess} strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <polyline className="path check" fill="none" stroke={theme.colors.checkSuccess} strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
          </>
          ) : (
            <>
            <circle className="path circle" fill="none" stroke={theme.colors.checkError} strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <line className="path line" fill="none" stroke={theme.colors.checkError} strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
            <line className="path line" fill="none" stroke={theme.colors.checkError} strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
          </>
        )}
      </svg>
    </Container>  
  )
};

export default AnimatedIcon;