import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  CSSProperties,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error, Label, ContainerField } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  LabelName: string;
  isFillable: boolean;
  isHalf: boolean;
  name: string;
  icon: React.ComponentType<IconBaseProps> | null;
  colorOptionalDefaul?: 'optional' | 'api';
}

const TextArea: React.FC<InputProps> = ({
  LabelName,
  isHalf,
  isFillable,
  name,
  icon: Icon,
  colorOptionalDefaul,
  ...rest
}: InputProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const [parentHeight, setParentHeight] = useState("auto");

  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [text, setText] = useState(defaultValue || null);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!textAreaRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value',
    });

    setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
    setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`);
  }, [fieldName, registerField, text]);

  const parentStyle: CSSProperties = {
    minHeight: parentHeight,
  };

  const textAreaStyle: CSSProperties = {
    height: textAreaHeight,
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight("auto");
    setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
    setText(event.target.value);
  };

  return (
    <>
      <ContainerField>
        <Label isHalf={isHalf}>{LabelName}:</Label>
        <Container error={!!error} isFilled={isFilled} isFocused={isFocused} isFillable={isFillable} style={parentStyle}>
          {Icon && <Icon size={20} />}
          <textarea
            rows={5}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            // defaultValue={defaultValue}
            onChange={onChangeHandler}
            style={textAreaStyle}
            ref={textAreaRef}
            {...rest}
          />

          {error && (
            <Error title={error}>
              <FiAlertCircle size={20} color="#c53030" />
            </Error>
          )}
        </Container>
      </ContainerField>
      <br />
    </>
  );
};

export default TextArea;
