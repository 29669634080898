export default {
  title: 'light',

  colors: {
    primary: '#FFFFFF',
    secondary: '#27B66D',

    background: '#FFFFFF',
    inputBackground: 'transparent',
    inputBackgroundFillable: '#FFFFFF',

    header: '#FFFFFF',

    text: '#000000',
    textHeader: '#4D217A',
    textInsideInput: '#000000',

    borderActive: '#0057C6',
    borderError: '#DA1E28',
    borderInactive: '#96B0C0',

    checkSuccess: '#27B66D',
    checkError: '#DA1E28',

    siemensPetrol: '#009999',

    redButton: '#DA1E28',
    yellowButton: '#F6E600',
    greenButton: '#14DA79',
  },
}