import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error, Label, ContainerField } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  LabelName: string;
  isFillable: boolean;
  isHalf: boolean;
  name: string;
  icon: React.ComponentType<IconBaseProps> | null;
  colorOptionalDefaul?: 'optional' | 'api';
}

const Input: React.FC<InputProps> = ({
  LabelName,
  isHalf,
  isFillable,
  name,
  icon: Icon,
  colorOptionalDefaul,
  ...rest
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <ContainerField>
        <Label isHalf={isHalf}>
          <span>
            {LabelName}:
          </span>
          </Label>
        <Container error={!!error} isFilled={isFilled} isFocused={isFocused} isFillable={isFillable}>
          {Icon && <Icon size={20} />}
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
          />

          {error && (
            <Error title={error}>
              <FiAlertCircle size={20} color="#c53030" />
            </Error>
          )}
        </Container>
      </ContainerField>
      <br />
    </>
  );
};

export default Input;
