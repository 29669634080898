/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { Options } from 'react-youtube';
import { useTranslation } from "react-i18next";

import {
  Container, Content, YoutubeContent
  // Video 
} from './styles';
import GlobalStyle from '../../styles/global';
import light from '../../styles/themes/light';


const Home: React.FC = () => {
  const [Theme, setTheme] = useState<DefaultTheme>(light);
  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    document.title = "Evoluenergy"
    setTheme(light);
  }, []);

  const opts: Options = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      modestbranding: 1,
      autoplay: 1,
      mute: 1,
      loop: 1,
      controls: 0,
      disablekb: 0,
      showinfo: 0,
      rel: 0,
      fs: 0,
      // hl: {string},
    }
  }

  return (
    <>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <Container>
          {/* <Video autoPlay muted loop x-webkit-airplay="allow" data-youtube-id="N9oxmRT2YWw" src="https://www.youtube.com/watch?v=8C-_1wav-mY" /> */}
          <YoutubeContent
            videoId={'YF39NtnfdxY'}
            opts={opts}
          />
          <Content>
            {ready && (
              <>
                <h1>{t("Welcome_to_Page")}</h1>
                <button onClick={() => changeLanguage("pt")}>PT</button>
                <button onClick={() => changeLanguage("en")}>EN</button>
              </>
            )}
          </Content>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Home;
